import React, {useEffect, useState} from 'react';
import videoClip from "../video/beauty.mp4";
import stacy from "../img/stacy.jpg";

function Video() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [show, setShow] = useState(true);
    const timeOut = 700;

    useEffect(() => {
        if (isLoaded) setTimeout(() => setShow(false), timeOut);
        console.log("SHOW Video", show)
    }, [isLoaded])
    const loadHandler = () => {
        console.log("Video loaded..!");
        setTimeout(() => setIsLoaded(true), 1000);
    }

    return (
        <>
            <div style={{
                display: show ? "flex" : "none",
                position: "fixed",
                inset: 0,
                opacity: isLoaded ? 0 : 1,
                backgroundColor: "white",
                zIndex: 1111,
                justifyContent: "center",
                alignItems: "center",
                transition: "opacity scale",
                transitionTimingFunction: "ease",
                transitionDuration: (timeOut + "ms"),
                transform: isLoaded && "scale(300%)"
            }}>
                <img src={stacy} style={{
                    width: "150px",
                    height: "150px",
                    filter: "invert(1)"
                }
                }/>
            </div>
            <video
                autoPlay
                muted
                loop
                playsInline
                onCanPlayThrough={loadHandler}
                style={{
                    // display: "none",
                    position: "fixed",
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: "110%",
                    zIndex: -1
                }}>
                <source src={videoClip} type="video/mp4"/>
            </video>
        </>
    );
}

export default Video;