import React, {useContext, useEffect, useState} from 'react';
import '../Styles/Inputs.css'
import {AppContext} from "../App";

function Inputs({lang}) {
    const defaultValues = {
        name: "",
        age: "",
        city: "",
        instagramNick: "",
        telMessenger: "",
        referral: ""
    }
    const [inputsValues, setInputsValues] = useState(defaultValues);
    const {formResetTrigger} = useContext(AppContext);

    useEffect(() => {
        setInputsValues(defaultValues);
        console.log("INputs values reseted!")
    },[formResetTrigger]);

    console.log("INPUTS block render!!")
    const telValidation = (event) => setInputsValues({...inputsValues, telMessenger: "+".concat(event.target.value.replace(/[^0-9]/g, ""))});
    const nickNameValidation = (event) => setInputsValues({...inputsValues, instagramNick: event.target.value.replace(/[^0-9a-zA-Z._@+]/g, "")});
    const telLostFocus = () => {if (inputsValues.telMessenger.length < 2) setInputsValues({...inputsValues, telMessenger: ""})};
    const defaultHandler = (e) => setInputsValues({...inputsValues, [e.target.name]: e.target.value});
    return (
        <>
            <div className="inputText blocker">
                <input type="text" name="lastname" autoComplete="off" placeholder="Last Name" maxLength="30"/>
            </div>
            <div className="inputText">
                <input type="text" name="name" value={inputsValues.name} onInput={defaultHandler} required autoComplete="off" placeholder={lang.name} maxLength="30"/>
            </div>
            <div className="inputText">
                <input type="text" name="age" value={inputsValues.age} onInput={defaultHandler} required autoComplete="off" placeholder={lang.age} maxLength="2" pattern="[1-7]{1}[0-9]{1}"/>
            </div>
            <div className="inputText">
                <input type="text" name="city" value={inputsValues.city} onInput={defaultHandler} required autoComplete="off" placeholder={lang.city} maxLength="20"/></div>
            <div className="inputText">
                <input type="text" name="instagramNick" value={inputsValues.instagramNick} required autoComplete="off" placeholder={lang.instagramNick} maxLength="20"
                onInput={nickNameValidation}/>
            </div>
            <div className="inputText">
                <input type="text" name="telMessenger" value={inputsValues.telMessenger} required autoComplete="off" placeholder={lang.telMessenger} maxLength="14" pattern="\+[0-9]{11,13}"
                onInput={telValidation}
                onBlur={telLostFocus}/>
            </div>
            <div className="inputText">
                <input type="text" name="referral" value={inputsValues.referral} onInput={defaultHandler} autoComplete="off" placeholder={lang.referral} maxLength="30"/>
            </div>
        </>
    );
}

export default Inputs;