import React, {useContext} from 'react';
import '../Styles/ModalPopUp.css';
import {AppContext} from "../App";

function ModalPopUp() {
    const {modalWindowText} = useContext(AppContext);
    console.log("Modal render!", modalWindowText)

    return (
        <div className="modal">
            <div className="childModal">
                <div id="modalWindowText" className="textData">{modalWindowText}</div>
            </div>
            <div className="caret"></div>
        </div>
    );
}

export default ModalPopUp;