import React from "react";
import '../Styles/Header.css'
import uk_logo from '../img/UKingdom.png'
import ua_logo from '../img/Ukraine.png'
import cz_logo from '../img/Czech.png'
import es_logo from '../img/Spain.png'
import ru_logo from '../img/Russia.png'
import {useRef} from "react";

function Header({lang, setLang}) {
    const r = useRef();
    return (
        <div className="languages">
            <ul>
                <li className={lang === "en" ? "active" : null} onClick={() => setLang("en")}><img src={uk_logo} alt="en"/></li>
                <li className={lang === "ua" ? "active" : null} onClick={() => setLang("ua")}><img src={ua_logo} alt="ua"/></li>
                <li className={lang === "cz" ? "active" : null} onClick={() => setLang("cz")}><img src={cz_logo} alt="cz"/></li>
                <li className={lang === "es" ? "active" : null} onClick={() => setLang("es")}><img src={es_logo} alt="es"/></li>
                <li className={lang === "ru" ? "active" : null} onClick={() => setLang("ru")}><img src={ru_logo} alt="ru"/></li>
                <div ref={r} className="indicator">
                    <div className="beforeTop"></div>
                    <div className="afterTop"></div>
                    <div className="beforeBottom"></div>
                    <div className="afterBottom"></div>
                </div>
            </ul>
        </div>
    );
}

export default React.memo(Header);
