import React, {useContext, useEffect, useState} from 'react';
import '../Styles/FeedBack.css';
import {AppContext} from "../App";

function FeedBack({lang: {header, text}}) {
    const [value, setValue] = useState("");
    const {formResetTrigger} = useContext(AppContext);
    console.log("Feedback render");
    const defaultHandler = (e) => setValue( e.target.value);

    useEffect(() => {
        setValue("");
    }, [formResetTrigger])

    return (
        <div className="labels">
            <div className="labelHeader">{header}
            </div>
            <div>
                <textarea name="message" placeholder={text} value={value} onInput={defaultHandler}></textarea>
            </div>
        </div>
    );
}

export default React.memo(FeedBack);