import './Styles/App.css';
import Header from "./Components/Header";
import Inputs from "./Components/Inputs";
import FeedBack from "./Components/FeedBack";
import InputTypeBlock from "./Components/InputTypeBlock";
import React, {useEffect, useRef, useState} from "react";
import Video from "./Components/Video";
export const AppContext = React.createContext(null);
function App(props) {
    // const advertising = ["Як Ви дізналися про лікаря Stacy?",
    //     {
    //         name: "radioButton",
    //         id: "Radio1",
    //         value: "Instagram",
    //         labelText: "Instagram"
    //     },
    //
    //     {
    //         name: "radioButton",
    //         id: "Radio2",
    //         value: "Facebook",
    //         labelText: "Facebook"
    //     },
    //
    //     {
    //         name: "radioButton",
    //         id: "Radio2_1",
    //         value: "Internet",
    //         labelText: "Internet"
    //     },
    //
    //     {
    //         name: "radioButton",
    //         id: "Radio3",
    //         value: "By recommendation",
    //         labelText: "By recommendation"
    //     }
    // ]
    // const checkBoxes = ["Яка процедура вас цікавить?",
    //     {
    //         name: "checkbox_list",
    //         id: "checkbox_1",
    //         value: "Аугментація губ",
    //         labelText: "Аугментація губ"
    //     },
    //
    //     {
    //         name: "checkbox_list",
    //         id: "checkbox_2",
    //         value: "Full face",
    //         labelText: "Full face"
    //     },
    //
    //     {
    //         name: "checkbox_list",
    //         id: "checkbox_3",
    //         value: "Скулы",
    //         labelText: "Скулы"
    //     },
    //
    //     {
    //         name: "checkbox_list",
    //         id: "checkbox_4",
    //         value: "Подбородок",
    //         labelText: "Подбородок"
    //     },
    //
    //     {
    //         name: "checkbox_list",
    //         id: "checkbox_5",
    //         value: "Botox",
    //         labelText: "Botox"
    //     },
    //
    //     {
    //         name: "checkbox_list",
    //         id: "checkbox_6",
    //         value: "Я еще не знаю...",
    //         labelText: "Я еще не знаю..."
    //     },
    //
    //     {
    //         name: "checkbox_list",
    //         id: "checkbox_7",
    //         value: "Другая процедура",
    //         labelText: "Другое...",
    //         inputElement: {
    //             name: "describe_other",
    //             text: "Опишите другие..."
    //         },
    //     }
    // ]
    // const allergy = ["У вас є алергія на лікарські препарати або на анестезію?",
    //     {
    //         name: "radioButton3",
    //         id: "Radio7",
    //         value: "‼️ Да, у меня есть аллергия ‼️",
    //         labelText: "Так, у мене є алергія на ліки",
    //         inputElement: {
    //             name: "describe-allergic",
    //             text: "Укажите на какие лекарства..."
    //         }
    //     },
    //
    //     {
    //         name: "radioButton3",
    //         id: "Radio8",
    //         value: "👍 Нет, у меня нет аллергии",
    //         labelText: "Ні, у мене немає алергії на ліки"
    //     },
    //
    //     {
    //         name: "radioButton3",
    //         id: "Radio9",
    //         value: "🤷‍♀️ Я не знаю...",
    //         labelText: "Я не знаю..."
    //     }
    // ]
    // const order = ["Наскільки терміново ви хочете пройти процедуру?",
    //     {
    //         name: "radioButton2",
    //         id: "Radio4",
    //         value: "Я готов(а) забронировать процедуру прямо сейчас",
    //         labelText: "це дуже терміново, я готовий забронювати це зараз"
    //     },
    //
    //     {
    //         name: "radioButton2",
    //         id: "Radio5",
    //         value: "Я буду ждать сколько потребуется",
    //         labelText: "Я буду чекати стільки, скільки буде потрібно"
    //     },
    //
    //     {
    //         name: "radioButton2",
    //         id: "Radio6",
    //         value: "Я просто присматриваюсь",
    //         labelText: "Я просто роздивляюсь"
    //     }
    // ]

    // const refs = [
    //     useRef({selected: false, showAlert: () => {}, reset: () => {}}),
    //     useRef({selected: false, showAlert: () => {}, reset: () => {}}),
    //     useRef({selected: false, showAlert: () => {}, reset: () => {}}),
    //     useRef({selected: false, showAlert: () => {}, reset: () => {}})
    // ];

    // const advertisingState = useRef({selected: false, showAlert: () => {}, reset: () => {}});
    // const checkBoxesState = useRef({selected: false, showAlert: () => {}, reset: () => {}});
    // const allergyState = useRef({selected: false, showAlert: () => {}, reset: () => {}});
    // const orderState = useRef({selected: false, showAlert: () => {}, reset: () => {}});

    const refsArr = useRef([]);
    const [formResetTrigger, setFormResetTrigger] = useState(false);
    const [dataLang, setDataLang] = useState(null);
    const [lang, setLang] = useState("en");
    // const [effect, setEffect] = useState(1)

    const formRef = useRef(null);

    console.log("App render!"/*, checked=", checked*/);
    useEffect(() => {             // сбрасывает данные формы при первой загрузке
        // setEffect(0);
        let st = setTimeout(() => setDataLang(null), 1000);
        fetch('http://192.168.1.140/data?lang='.concat(lang), {
            // signal: AbortSignal.timeout(1000),
            // mode: 'no-cors',
            method: 'GET',
            headers: {
                Accept: "application/json"
            }
        }).then(data => data.json()).then(data => {
            clearTimeout(st);
                setDataLang(data);
                setFormResetTrigger(!formResetTrigger)
            // setTimeout(() => {
            //     // formRef.current.reset();
            //     // setEffect(1);
            // }, 300);

        }).catch(() => setDataLang(null));
    }, [lang]);

    // useEffect(() => {             // сбрасывает данные формы при первой загрузке
    //     formRef.current.reset();
    //     // setEffect(1);
    //     fetch('http://192.168.32.156/data?lang='.concat(lang), {
    //         // mode: 'no-cors',
    //         method: 'GET',
    //         headers: {
    //             Accept: "application/json"
    //         }
    //     }).then(data => data.json()).then(data => {
    //         console.log("DATA", data)
    //         setDataLang(data)
    //     }).catch(() => setDataLang(null));
    // }, []);

    return (
        <>
        <Video isLoaded={false}/>
        <div className="container">
            {/*{React.Children.map(props.children, child => {*/}
            {/*    // Клонируем каждый дочерний элемент, добавляя или изменяя его пропсы*/}
            {/*    return React.cloneElement(child, {style: {...child.props.style, color: "red"}})*/}
            {/*})}*/}
            {/*{React.createElement("div", {className: "my__div", children: React.cloneElement(props.children[1]), style: {padding: "5px", ...props.children[1].props.style}})}*/}
            <Header lang={lang} setLang={setLang}/>
            <div className="content" id="wrapper">
                <form ref={formRef}
                      id="myForm"
                      action={"/".concat(lang, "/")}
                      method="post"
                      onSubmit={(event) => {
                          let allFieldsChecked = true;
                    // [orderState, allergyState, checkBoxesState, advertisingState].forEach((elem) => {
                    [...refsArr.current].reverse().forEach((elem) => {
                        if (!elem.current.selected) {
                            elem.current.showAlert(true);
                            elem.current.scrollIntoView({behavior: "smooth", block: "center"});
                            allFieldsChecked = false;

                        }
                    })
                          if (!allFieldsChecked) return event.preventDefault();
                          console.log('Form SUBMIT');
                          setFormResetTrigger(!formResetTrigger);
                      }}>
                    {/*{console.log("dataLang: ", dataLang)}*/}
                    {/*{console.log("refsArr: ", refsArr)}*/}
                    {dataLang ? <AppContext.Provider value={{formResetTrigger, modalWindowText: dataLang.modalWindowText, refsArr}}>
                        <fieldset>
                            <legend>{dataLang && dataLang.formHeader}</legend>
                            <Inputs lang={dataLang.inputs}/>
                            {dataLang.labels.map((block, index) => <InputTypeBlock key={block.header} index={index}/*effect={effect}*/ /*ref={refs[index]}*/ elementsArray={block}/>)}
                            <FeedBack lang={dataLang.feedBack}/>
                            <input type="submit" name="submit" value={dataLang.buttons.submit} onClick={() => {console.log()}}/>
                            {' '}
                            <input type="reset" name="clear" value={dataLang.buttons.reset} onClick={() => setFormResetTrigger(!formResetTrigger)}/>
                        </fieldset>
                    </AppContext.Provider>
                    :
                    <h2 style={{
                        padding: "10px",
                        backgroundColor: "rgb(250, 225, 255)",
                        borderRadius: "6px",
                        border: "2px solid rgba(121, 68, 217, 0.6)"
                    }}>Loading data...</h2>}
                </form>

            </div>
        </div>
            </>
    );
}

export default App;
