import React, {useContext, useEffect, useRef, useState} from 'react';
import '../Styles/InputTypeBlock.css';
import ModalPopUp from "./ModalPopUp";
import InlineINPUT from "./inlineComponents/InlineINPUT";
import {AppContext} from "../App";

const InputTypeBlock = ({elementsArray, hidden, ...props}) => {
    let {formResetTrigger, refsArr} = useContext(AppContext);

    const ref = useRef({selected: false, showAlert: () => {}, reset: () => {}})
    refsArr.current[props.index] = ref;
    const [checked, setChecked] = useState(new Array(elementsArray.data.length).fill(false));
    const [modalState, setModalState] = useState(false);
    ref.current.showAlert = setModalState;
    // ref.current.reset = () => {
    //     setChecked(new Array(elementsArray.length -1).fill(false));
    //     setModalState(false);
    //     ref.current.selected = false;
    // }
    // React.useImperativeHandle(ref, () => ({
    //     setModalState,
    //     state: ""
    // }));

    useEffect(() => {
        setChecked(new Array(elementsArray.data.length).fill(false));
        setModalState(false);
        ref.current.selected = false;
    },[formResetTrigger]);

    if (hidden) return (<></>);

    const {header, type, data: elements} = elementsArray;

    function onChangeHandler(index, checked) {
        let array = [];
        setModalState(false);
        if (type === "checkbox") setChecked([...array = checked.toSpliced(index, 1, !checked[index])]);
        if (type === "radio") setChecked([...array = checked.fill(false).toSpliced(index, 1, true)]);
        ref.current.selected = array.indexOf(true) !== -1;
    }
    console.log("InputTypeBlock render!", props);

    return (
        // <div className={`labels ${props.showModal &&  "alert"}`} hidden={props.hidden}>
        <div ref={ref} className={modalState ? "labels alert" : "labels"}>
            <div className="labelHeader" /*style={{opacity: props.effect}}*/>{header}</div>
            <hr/>
            {modalState && <ModalPopUp/>}
            {
                elements.map((element, index) => (
                    <div className="labelElements" /*style={{opacity: props.effect}}*/ key={element.id}>
                        <input className="inputElements" type={type}
                               name={element.name}
                               id={element.id}
                               value={element.value}
                               required={!checked}
                               checked={checked && checked[index]}
                               onChange={checked && (() => onChangeHandler(index, checked))}
                        />{' '}
                        {element.inputElement && checked[index]
                            ?
                            <InlineINPUT id={element.inputElement.name} name={element.inputElement.name} placeholder={element.inputElement.text}/>
                            :
                            (<label id="" htmlFor={element.id}>
                                {element.labelText}
                            </label>)}
                    </div>
                ))
            }

        </div>
    );
}

export default React.forwardRef(InputTypeBlock);