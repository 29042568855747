import React from 'react';

function InlineINPUT({id, name, placeholder}) {
    return React.createElement("input", {
        id: id,
        name: name,
        type: "text",
        autoComplete: "off",
        placeholder: placeholder,
        required: true,
        maxLength: 50,
        style: {
            margin: "-10px 0",
            width: "250px",
            // maxWidth: "65%"
        }
    });
}

export default InlineINPUT;